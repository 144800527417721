import React from 'react';
import { Card } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';


export interface IAppProps {
    children: React.ReactNode,
    className: string
}

const theme = createTheme({
    typography: {
        fontFamily: 'Poppins'
    },
    components: {
        MuiCardHeader: {
            styleOverrides: {
                title: {
                    fontFamily: 'Poppins'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Poppins',
                    background: 'linear-gradient(to right, #75B9F0, #1085FD)'
                }
            }
        }
    }
});

function LoginCard(props: IAppProps) {

    return (
        <ThemeProvider theme={theme}>
            <Card 
            sx={{
                borderRadius: '10px',
                boxShadow: 0.5
            }}
            className={props.className} variant='outlined'>{props.children}</Card>
        </ThemeProvider>
    );
}

export default LoginCard;

