/* This file contains information about the validation schemas used in the application
 * Date Created: 12/09/2023
 * Author: @charliedua
 */

import * as yup from "yup";

export const basicTextBoxRegEx: RegExp = /^[a-zA-Z0-9@._\-'& ]+$/;

export const loginFormSchema = yup.object({
    email: yup.string()
        .matches(/^[-.@_a-z0-9 +]+$/gi, 'Invalid Charater')
        .required('UserName is required'),
    password: yup.string()
        .required('Password is required'),
});